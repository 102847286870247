import { Col, Space, Row, Badge, List, Typography } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'

import { MaterialFormSectionRowTitle } from '../MaterialFormSectionRowTitle'
import { WastageIcon } from '../WastageIcon'
import { FixedPriceProfitCostIcon } from '../FixedPriceProfitCostIcon'
import { MaterialNameDescription } from '../MaterialNameDescription'
import { MaterialsListItemMobile } from './MaterialsListItemMobile'
import { MaterialsListItemSectionMobile } from './MaterialsListItemSectionMobile'
import { ServiceItemBadge } from '../ServiceItemBadge'
import { formatMoney, calculateLineItemProfit } from '../../utils'
import { MaterialsListStyled, MaterialsListMobileStyled } from './styles'

interface MaterialsListData {
  name?: string
  unit_amount: number
  amount: number
  quantity: string
  markup: string
  prices: [
    {
      unit_amount: number
      product: {
        name: string
      }
    }
  ]
  metadata?: any
}
export interface MaterialsListProps {
  data?: MaterialsListData[] | any // TODO: ProductDetailFragment[]
  mobile?: boolean
  selectedId?: string
  disableMarkup?: boolean
  onMaterialClick?: (material: any) => void
}

export const MaterialsList = ({
  data = [],
  mobile,
  selectedId,
  disableMarkup,
  onMaterialClick = () => undefined,
}: MaterialsListProps) => {
  const materialsWithSections: any = []
  const sortedData = [...data]

  sortedData.sort(
    (a: any, b: any) => a.metadata?.sequence - b.metadata?.sequence
  )

  sortedData.forEach((material: any) => {
    const { metadata } = material || {}
    const { section } = metadata || {}
    const sectionIndex = materialsWithSections.findIndex(
      (item: any) => item.section === section
    )
    if (sectionIndex === -1) {
      materialsWithSections.push({
        section,
        materials: [material],
      })
    } else {
      materialsWithSections[sectionIndex].materials.push(material)
    }
  })

  const renderMaterialItems = (materials: any) => {
    return materials.map((item: any, $index: number) => {
      const {
        quantity,
        amount,
        fixed_price,
        unit_amount,
        markup,
        name,
        description,
        type,
        wastage_amount,
        wastage_percentage,
        coverage_rate,
        source_type,
      } = item || {}
      const price = item?.prices ? item?.prices[0] : { product: { name: '' } }
      // @ts-ignore
      const { product } = price || {}

      return (
        <Row className='list-item' gutter={16} key={$index}>
          <Col className='list-item-cell name' span={8}>
            <div style={{ margin: '15px 0' }}>
              {type === 'service' && (
                <>
                  <span>{product?.description || name}</span>

                  <span style={{ float: 'right' }}>
                    <ServiceItemBadge />
                  </span>
                </>
              )}
              {type != 'service' && (
                <>
                  {product?.id ? (
                    <span>
                      <a
                        href={`/materials/${product.id}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{ color: theme.colors.primary }}
                      >
                        <MaterialNameDescription
                          copyName={false}
                          boldName={false}
                          name={product?.name || name}
                          description={product?.description || description}
                          globalCatalog={source_type === 'global'}
                        />
                      </a>
                    </span>
                  ) : (
                    <MaterialNameDescription
                      copyName={false}
                      boldName={false}
                      name={product?.name || name}
                      description={product?.description || description}
                      globalCatalog={source_type === 'global'}
                    />
                  )}
                </>
              )}
            </div>
          </Col>
          <Col className='list-item-cell quantity' span={3}>
            <p>{quantity || 1}</p>
          </Col>

          <Col className='list-item-cell quantity' span={1}>
            <div style={{ marginTop: '14px' }}>
              {wastage_amount && (
                <WastageIcon
                  amount={wastage_amount}
                  percent={wastage_percentage}
                  coverageCapacity={
                    product.coverage_rate
                      ? `${product.coverage_rate * quantity}sqft`
                      : null
                  } // TODO update with org settings unit}
                />
              )}
            </div>
          </Col>

          <Col className='list-item-cell price' span={4}>
            <p>{formatMoney(fixed_price || unit_amount)}</p>
          </Col>
          <Col className='list-item-cell markup' span={4}>
            {!fixed_price && !disableMarkup && <p>{markup || 0}%</p>}
          </Col>
          <Col className='list-item-cell total' span={4}>
            <p>{formatMoney(amount)}</p>
          </Col>
        </Row>
      )
    })
  }

  const renderMaterialsDesktop = () => (
    <MaterialsListStyled>
      <>
        <Row gutter={16}>
          <Col span={8}>
            <p className='list-header name'>Item name</p>
          </Col>
          <Col span={3}>
            <p className='list-header quantity'>Quantity</p>
          </Col>
          <Col span={1} />
          <Col span={4}>
            <p className='list-header price'>Price</p>
          </Col>
          <Col span={4}>
            <p className='list-header markup'>Markup</p>
            {/* <i>+ Use all previous prices</i> */}
          </Col>
          <Col span={4}>
            <p className='list-header total'>Total</p>
          </Col>
        </Row>

        {/* <Divider style={{ marginTop: 0 }} /> */}

        {materialsWithSections.length &&
          materialsWithSections.map(
            ({ section, materials }: any, $index: number) => {
              return (
                <span key={$index}>
                  {section && (
                    <Row>
                      <Col span={8}>
                        <MaterialFormSectionRowTitle
                          sectionTitle={section}
                          viewOnly
                        />
                      </Col>
                    </Row>
                  )}
                  {renderMaterialItems(materials)}
                </span>
              )
            }
          )}
      </>
    </MaterialsListStyled>
  )

  const renderMaterialsMobile = () => (
    <MaterialsListMobileStyled>
      <>
        {materialsWithSections.map(
          ({ section, materials }: any, sectionIndex: number) => (
            <div key={sectionIndex}>
              {section && <MaterialsListItemSectionMobile title={section} />}

              <List
                dataSource={materials}
                renderItem={(item: any) => {
                  const {
                    quantity,
                    amount,
                    fixed_price,
                    unit_amount,
                    markup,
                    name,
                    description,
                    type,
                    wastage_amount,
                    wastage_percentage,
                    source_type,
                  } = item || {}
                  const price = item?.prices
                    ? item?.prices[0]
                    : { product: { name: '' } }
                  const { product } = price || {}

                  return (
                    <MaterialsListItemMobile
                      title={
                        <div className='item-name'>
                          {type === 'service' ? (
                            <div
                              className='item-header'
                              style={{ marginBottom: '10px' }}
                            >
                              <span>{product?.description || name}</span>
                              <ServiceItemBadge />
                            </div>
                          ) : (
                            <MaterialNameDescription
                              copyName={false}
                              boldName={false}
                              name={product?.name || name}
                              description={product?.description || description}
                              globalCatalog={source_type === 'global'}
                            />
                          )}
                        </div>
                      }
                      total={
                        <Space direction='horizontal'>
                          {formatMoney(amount)}
                          {wastage_amount && (
                            <WastageIcon
                              amount={wastage_amount}
                              percent={wastage_percentage}
                              coverageCapacity={
                                product.coverage_rate
                                  ? `${product.coverage_rate * quantity}sqft`
                                  : null
                              }
                            />
                          )}
                        </Space>
                      }
                      metaInfo={[
                        { label: 'Quantity', value: quantity || 1 },
                        {
                          label: 'Price',
                          value: formatMoney(fixed_price || unit_amount),
                        },
                        {
                          label: 'Markup',
                          value:
                            !fixed_price && !disableMarkup ? `${markup}%` : '-',
                        },
                      ]}
                    />
                  )
                }}
              />
            </div>
          )
        )}
      </>
    </MaterialsListMobileStyled>
  )

  return mobile ? renderMaterialsMobile() : renderMaterialsDesktop()
}

MaterialsList.displayName = 'MaterialsList'
