import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import { Layers } from 'react-feather'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'

import { FooterToolbar } from '@ant-design/pro-components'
import {
  Col,
  Row,
  Form,
  Typography,
  message,
  Modal,
  Button,
  Input,
  InputNumber,
  Divider,
  Space,
  Collapse,
  DatePicker,
  Popover,
  Tooltip,
  Tour,
} from 'antd'

import React from 'react'
import {
  useParams,
  useSearchParams,
  redirect,
  useNavigate,
} from 'react-router-dom'

import {
  useGetTaxesQuery,
  TaxDetailFragment,
  CustomerDetailFragment,
  EstimateDetailFragment,
  SettingsDetailFragment,
  useCreateEstimateMutation,
  useUpdateEstimateMutation,
  useSaveEstimateDraftMutation,
} from '../../graphql/_generated-hooks'
import {
  formatCentsToDollars,
  multiply,
  roundMoney,
  calculateLineItemTotal,
  calculateLineItemProfit,
  calculateSubtotalWithDiscount,
  formatEstimateFormObj,
  setApiFormErrors,
  setGraphQLFormErrors,
  filterAttributes,
} from '../../utils'
import { getEstimateOnboardingSteps } from './onboardingSteps'
import { useAppStore } from '../../stores/appStore'

// import messages from './messages'
import { EstimatesFormStyled } from './styles'
import { CustomerSelect } from '../CustomerSelect'
import { TaxInputManager } from '../TaxInputManager'
import { MaterialListBuilder } from '../MaterialListBuilder'
import { TaxFormDrawer } from '../TaxFormDrawer'
import { CustomerFormDrawer } from '../CustomerFormDrawer'
import { CustomerDetailCard } from '../CustomerDetailCard'
import { EstimatesMaterialsForm } from './EstimatesMaterialsForm'
import { EstimatesFormLabor } from './EstimatesFormLabor'
import { EstimatesFormTaxTotalSummary } from './EstimatesFormTaxTotalSummary'
import { EstimatesFormTotalDescription } from './EstimatesFormTotalDescription'
import { CurrencySelect } from '../CurrencySelect'
import { RichTextEditor } from '../RichTextEditor'
import { AutoSavingLabel } from '../AutoSavingLabel'
import { MaterialFormDrawer } from '../MaterialFormDrawer'
import { CategoryCreateFormDrawer } from '../CategoryCreateFormDrawer'
import { DistributorCreateFormDrawer } from '../DistributorCreateFormDrawer'
import { MaterialListSelectorModal } from '../MaterialListSelectorModal'
import { CustomSubtotalModal } from '../CustomSubtotalModal'
import { DiscountInputManager } from '../DiscountInputManager'
import { set } from 'lodash'

const defaultCurrency = 'USD'

interface EstimateDetailFormFragment extends EstimateDetailFragment {
  parentId?: string
}

export interface EstimatesFormProps {
  edit?: boolean
  mobile?: boolean
  onboarding?: boolean
  lastEstimateNumber?: string | null
  isSaving?: boolean
  parentId?: string
  initialValues?: Partial<EstimateDetailFormFragment>
  navigationCollapsed?: boolean
  onSubmit?(values: EstimatesFormSchema): void
  onCancel?(): void
  onOnboardingComplete?(): void
}

export interface EstimatesFormSchema {
  number: string
  taxes: any[]
}

// TODO abstract
const sortAndGroupBySections = (line_items: any) => {
  let formattedItems: any = []
  let sortedLineItems = [...line_items]

  sortedLineItems.sort(
    (a: any, b: any) => a.metadata?.sequence - b.metadata?.sequence
  )

  // Group
  sortedLineItems.forEach((item: any) => {
    const { metadata } = item || {}
    const { section } = metadata || {}
    const sectionIndex = formattedItems.findIndex(
      (item: any) => item.section === section
    )

    let formattedItem = {
      // id: item?.id,
      id:
        item?.source_type === 'global'
          ? item?.source_global_product_id
          : item?.prices?.[0]?.product?.id,
      name: item?.name || item?.prices?.[0]?.product?.name,
      description: item?.description || item?.prices?.[0]?.product?.description,
      priceId:
        item?.source_type === 'global' ? '_global' : item?.prices?.[0]?.id,
      price: item.unit_amount || item?.prices?.[0]?.unit_amount,
      markup: item?.markup,
      quantity: item?.quantity,
      type: item?.type || 'good',
      prices: item?.prices || [],
      fixed_price: item?.prices?.[0]?.product?.fixed_price,
      wastage_amount: item?.wastage_amount,
      wastage_percentage: item?.wastage_percentage,
      ...(item.coverage_rate && {
        total_coverage_rate: `${item.coverage_rate * item.quantity}sqft`,
      }), // TODO update with org settings unit
      metadata: item?.metadata,
    }

    if (sectionIndex === -1) {
      formattedItems.push({
        section,
        materials: [formattedItem],
      })
    } else {
      formattedItems[sectionIndex].materials.push(formattedItem)
    }
  })

  // Sort
  formattedItems.forEach((group: any) => {
    group.materials.sort(
      (a: any, b: any) => a.metadata?.sequence - b.metadata?.sequence
    )
  })

  return formattedItems
}
const flattenSectionGroups = (lineItemsGrouped: any) => {
  let flattenedItems: any = []

  lineItemsGrouped.forEach((group: any) => {
    if (group.section) {
      flattenedItems.push({ type: 'section', name: group.section })
    }

    if (group.materials && group.materials.length) {
      flattenedItems = flattenedItems.concat(group.materials)
    }
  })

  return flattenedItems
}

const setFormFieldsFromObject = (
  form: any,
  initialValuesObj: any,
  setInitialMaterials?: any
) => {
  const {
    number,
    estimate_date,
    expiration_date,
    line_items = [],
    job,
    taxes,
    work_scope,
    additional_content,
    currency,
    labor_breakdown,
    ...rest
  } = initialValuesObj

  const formattedLineItems = flattenSectionGroups(
    sortAndGroupBySections(line_items)
  )

  form.setFieldsValue({
    number,
    estimate_date: estimate_date ? dayjs(estimate_date) : null,
    expiration_date: expiration_date ? dayjs(expiration_date) : null,
    ...(line_items.length && {
      // Flatten for use in the material list builder
      materials: formattedLineItems,
    }),
    customer: job?.customers?.[0]?.id,
    work_scope,
    ...(additional_content && { additional_content }),
    currency: currency || defaultCurrency,
    labor_breakdown: filterAttributes(labor_breakdown, [
      'label',
      'quantity',
      'rate',
      'total',
    ]),
    _totals: {},
    ...rest,
  })

  setInitialMaterials(formattedLineItems)
}

export const EstimatesForm = ({
  edit,
  mobile,
  onboarding,
  lastEstimateNumber,
  parentId,
  initialValues,
  navigationCollapsed,
  onSubmit = () => undefined,
  onCancel = () => undefined,
  onOnboardingComplete = () => undefined,
}: EstimatesFormProps) => {
  // State
  const navigate = useNavigate()
  const { id: estimateIdParam } = useParams()
  const [form] = Form.useForm<any>()
  const [messageApi, contextHolder] = message.useMessage()
  const [showOnboarding, setShowOnboarding] = React.useState(!!onboarding)
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<CustomerDetailFragment | null>()
  const [createdCustomer, setCreatedCustomer] =
    React.useState<CustomerDetailFragment | null>()
  const [jobId, setJobId] = React.useState<string | null | undefined>(
    initialValues?.job?.id
  )

  const [createTax, setCreateTax] = React.useState(false)
  const [quickCreateMaterial, setQuickCreateMaterial] =
    React.useState<any>(null)
  const [createCustomer, setCreateCustomer] = React.useState(false)
  const [showAddAssemblyModal, setShowAddAssemblyModal] = React.useState(false)
  const [showCustomSubtotalModal, setShowCustomSubtotalModal] =
    React.useState(false)
  const [createDistributor, setCreateDistributor] = React.useState(false)
  const [createdDistributor, setCreatedDistributor] = React.useState(null)
  const [createCategory, setCreateCategory] = React.useState(false)
  const [createdCategory, setCreatedCategory] = React.useState(null)
  const [hasCustomSubtotal, setHasCustomSubtotal] =
    React.useState<boolean>(false)
  const [formattedInitialFormValues, setFormattedInitialFormValues] =
    React.useState<any>()
  const [prevEstimateNumber, setPrevEstimateNumber] = React.useState<any>()
  const [appliedAssembly, setAppliedAssembly] = React.useState<any>([]) // Can only have one applied assembly at a time, sub components can use Effects to listen for changes
  const [initialMaterials, setInitialMaterials] = React.useState<any[]>([])
  const [addedMaterialList, setAddedMaterialList] = React.useState<any[]>([])
  const [appliedTaxes, setAppliedTaxes] = React.useState<any[]>([])
  const [inlineCreatedMaterials, setInlineCreatedMaterials] =
    React.useState<any>({})
  const [selectedCurrency, setSelectedCurrency] =
    React.useState<string>(defaultCurrency)

  // Refs
  // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback/62453660#62453660
  let selectedCustomerRef: any = React.useRef(null)
  let appliedTaxesRef: any = React.useRef([])
  let draftEstimate: any = React.useRef({})
  let draftEstimateError: any = React.useRef('')

  // Hooks
  const [createEstimate, { data, loading: isCreatingEstimate, error }] =
    useCreateEstimateMutation()
  const [updateEstimate, { loading: isUpdatingEstimate }] =
    useUpdateEstimateMutation()
  const [saveEstimateDraft, { loading: isSavingDraftEstimate }] =
    useSaveEstimateDraftMutation()

  // State
  const addEstimate = useAppStore((state: any) => state.addEstimate)
  const setTotals = useAppStore((state: any) => state.setTotals)
  const setProfits = useAppStore((state: any) => state.setProfits)
  const estimateFormErrors = useAppStore(
    (state: any) => state.estimateFormErrors
  )

  const footerToolbarStyle = {
    right: 0,
    ...(!mobile && {
      left: navigationCollapsed ? '50px' : '175px',
      padding: '1em 2em',
    }),
    ...(mobile && { left: 0, padding: '1em' }),
    width: 'auto',
  }

  const { data: { taxes = [] } = { taxes: [] }, updateQuery: updateTax } =
    useGetTaxesQuery({
      variables: { input: {} },
    })

  const estimateInfoRef = React.useRef(null)
  const estimateScopeRef = React.useRef(null)
  const estimateItemsRef = React.useRef(null)
  const estimateTotalsRef = React.useRef(null)
  const estimateSaveRef = React.useRef(null)

  const onboardingSteps = getEstimateOnboardingSteps(
    {
      information_section: estimateInfoRef.current,
      project_scope: estimateScopeRef.current,
      items_section: estimateItemsRef.current,
      totals_section: estimateTotalsRef.current,
      save_button: estimateSaveRef.current,
    },
    mobile
  )

  React.useEffect(() => {
    setShowOnboarding(!!onboarding)
  }, [onboarding])

  // Prefill when editing
  React.useEffect(() => {
    if (initialValues && Object.keys(initialValues).length) {
      setFormFieldsFromObject(form, initialValues, setInitialMaterials)

      handleSetSelectedCustomer(initialValues.job?.customers?.[0] || null)
      setHasCustomSubtotal(!!initialValues.custom_subtotal)

      if (initialValues.status === 'draft') {
        draftEstimate.current = initialValues
      }

      // Tigger calculation
      const allValues = form.getFieldsValue()
      if (initialValues.taxes && initialValues.taxes.length) {
        handleSetAppliedTaxes(initialValues.taxes)
        calculateTotals(allValues, initialValues.taxes)
      } else {
        calculateTotals(allValues, [])
      }
    }

    if (lastEstimateNumber) {
      setPrevEstimateNumber(lastEstimateNumber)
    }
  }, [initialValues, lastEstimateNumber])

  const onDistributorSave = (distributor: any) => {
    setCreatedDistributor(distributor)
    setCreateDistributor(false)
  }
  const onCategorySave = (category: any) => {
    setCreatedCategory(category)
    setCreateCategory(false)
  }
  const handleDistributorFormClose = () => {
    setCreateDistributor(false)
  }
  const handleCategoryFormClose = () => {
    setCreateCategory(false)
  }

  const calculateTotals = React.useCallback(
    (formValues: any = {}, taxes: any[]) => {
      const {
        materials,
        labor_rate,
        labor_quantity,
        custom_subtotal,
        discount_percentage,
      } = formValues

      let subtotal = 0
      let taxtotal = 0
      let materialCost = 0
      let laborCost = 0
      let cost = 0
      let total = 0
      let materialProfit = 0
      let laborProfit = 0
      let profit = 0

      if (materials && materials.length) {
        materials.forEach((material: any, index: number) => {
          if (!material) return
          const { price, quantity, markup, fixed_price } = material

          let lineCost = calculateLineItemTotal(price, quantity) // Calculate cost by omitting markup
          let lineProfit = calculateLineItemProfit(
            price,
            quantity,
            markup,
            fixed_price
          )
          let lineTotal = calculateLineItemTotal(
            price,
            quantity,
            markup,
            fixed_price
          )

          if (materials[index]) materials[index]['total'] = lineTotal
          cost += lineCost
          profit += lineProfit
          subtotal += lineTotal

          if (material.type === 'good') {
            materialCost += lineCost
            materialProfit += lineProfit
          } else {
            laborCost += lineCost
            laborProfit += lineProfit
          }
        })
      }

      // Simply override the subtotal
      if (custom_subtotal) {
        subtotal = custom_subtotal
        profit = subtotal - cost
      }

      taxes.forEach((tax) => {
        taxtotal += multiply([subtotal, tax.percentage / 100])
      })

      if (discount_percentage) {
        total =
          calculateSubtotalWithDiscount(subtotal, discount_percentage) +
          taxtotal
      } else {
        total = subtotal + taxtotal
      }

      setTotals(
        subtotal,
        total,
        cost,
        laborCost,
        materialCost,
        custom_subtotal,
        discount_percentage
      )
      setProfits(profit, laborProfit, materialProfit)
    },
    []
  )

  const handleAutoSave = React.useCallback(
    debounce(async (draftId, jobId) => {
      const values = await form.getFieldsValue()
      saveEstimateDraft({
        // Caching this mutation will cause re-renders of the parent component
        // which we don't want as it will re-render the material list builder
        fetchPolicy: 'no-cache',
        variables: {
          ...(draftId && draftId && { id: draftId }),
          ...(parentId && { parentId }),

          // Pass in refs since this is saved programmatically
          input: formatEstimateFormObj(
            values,
            selectedCustomerRef.current,
            appliedTaxesRef.current
          ),
        },
        onCompleted({ saveEstimateDraft }: any = {}) {
          draftEstimate.current = saveEstimateDraft.data
          draftEstimateError.current = ''
        },
        onError(error, clientOptions) {
          draftEstimateError.current = error.message
          setApiFormErrors(error.graphQLErrors, form)
        },
      })
    }, 5000),
    []
  )
  const handleFormChange = async (changedValues: any, allValues: any) => {
    calculateTotals(allValues, appliedTaxes)

    handleAutoSave(draftEstimate.current.id, jobId)
  }

  const handleSubmit = async () => {
    handleAutoSave.cancel()

    try {
      const values = await form
        .validateFields()
        .then((values) => {
          // Somehow this fixes the 'name' error message from showing up in
          // two places.
          return values
        })
        .catch((errorInfo) => {
          const firstErrorField = errorInfo.errorFields?.[0]
          form.scrollToField(firstErrorField?.name?.[0])
          messageApi.error(`Form error: ${firstErrorField?.errors[0]}`)
        })

      const inputData = formatEstimateFormObj(
        values,
        selectedCustomer,
        appliedTaxes
      )

      if (edit && initialValues?.id) {
        updateEstimate({
          variables: {
            // Update the parent estimate if we are editing a draft
            id: parentId || initialValues.id,
            input: inputData,
          },
          onCompleted({ updateEstimate }: any = {}) {
            onSubmit(updateEstimate?.data)
            messageApi.success('Success: Estimate updated successfully!')
            if (updateEstimate?.data?.id) {
              navigate(`/estimates/${updateEstimate.data.id}`, {
                replace: true,
              })
            }
          },
          onError(error, clientOptions) {
            // TODO consolidate error handling & fix GQL validation errors
            messageApi.error('An error occurred. Please try again.')
            setApiFormErrors(error.graphQLErrors, form)
          },
        })
      } else {
        createEstimate({
          variables: {
            input: inputData,
          },
          onCompleted({ createEstimate }: any = {}) {
            onSubmit(createEstimate?.data)
            messageApi.success('Success: Estimate created successfully!')
            if (createEstimate?.data?.id) {
              addEstimate()
              navigate(`/estimates/${createEstimate.data.id}`, {
                replace: true,
              })
            }
          },
          onError(error, clientOptions) {
            if (error?.graphQLErrors?.[0]?.message) {
              messageApi.error(`Error: ${error?.graphQLErrors?.[0]?.message}`)
            } else {
              messageApi.error('An error occurred. Please try again.')
            }
            setApiFormErrors(error.graphQLErrors, form)
          },
        })
      }
    } catch (errInfo: any) {
      // GraphQL validation errors will catch here
      if (errInfo && errInfo?.errorFields?.length) {
        // Adds scroll to
        setGraphQLFormErrors(errInfo.errorFields, form)
        messageApi.error(
          `An error occurred: ${errInfo.errorFields[0]?.errors[0]}`
        )
      }
    }
  }
  const handleCancel = () => {
    onCancel()
    if (edit && estimateIdParam) {
      navigate(`/estimates/${estimateIdParam}`, { replace: true })
    } else {
      navigate(`/estimates`, { replace: true })
    }
  }

  // const isSubmitButtonDisabled = () => {
  //   return estimateFormErrors && Object.keys(estimateFormErrors).length > 0
  // }

  const handleSetSelectedCustomer = (customerObj: any) => {
    selectedCustomerRef.current = customerObj
    setSelectedCustomer(customerObj)

    if (!form.isFieldTouched('contact_name') && customerObj) {
      form.setFieldValue('contact_name', customerObj.name)
    }
    if (!form.isFieldTouched('contact_email') && customerObj) {
      form.setFieldValue('contact_email', customerObj.email)
    }
  }
  const handleSetAppliedTaxes = (taxes: any[]) => {
    appliedTaxesRef.current = taxes
    setAppliedTaxes(taxes)
  }
  const handleTaxCreated = (taxObj: TaxDetailFragment) => {
    updateTax((prev) => {
      let previousTaxes = prev?.taxes || []
      return {
        taxes: [...previousTaxes, taxObj],
      }
    })
    handleSetAppliedTaxes([taxObj, ...appliedTaxes])
    setCreateTax(false)
  }
  const handleCreateTax = () => {
    setCreateTax(true)
  }
  const handleNavigateToSettings = () => {
    navigate(
      `/settings/estimates?referrer=estimates&mode=edit${
        initialValues?.id ? '&id=' + initialValues?.id : ''
      }`
    )
  }
  const handleSetPreviousEstimateNumber = () => {
    form.setFieldValue('number', prevEstimateNumber)
  }
  const handleAddAssemblyItems = (materialList: any, customName?: string) => {
    // const allValues = form.getFieldsValue()

    let materials = form.getFieldValue('materials')

    let newMaterials = materialList?.line_items
      ?.sort((a: any, b: any) => a.metadata?.sequence - b.metadata?.sequence)
      .map((item: any) => {
        let product = item?.products?.[0]
        let price = product?.prices?.[0]

        return {
          id: product.id,
          name: item.name || product.name,
          description: item.description,
          fixed_price: product.fixed_price,
          quantity: item.quantity || 1,
          priceId: price?.id,
          price: price?.unit_amount,
          prices: item?.prices || [], // Needed for subcomponent to check for custom input
          markup: item.markup || 0,
          type: item.type || 'good',
          wastage_amount: item.wastage_amount,
          wastage_percentage: item.wastage_percentage,
          total_coverage_rate: `${item.coverage_rate * item.quantity}sqft`, // TODO update with org settings unit
          custom: false,
        }
      })

    setAppliedAssembly([
      {
        type: 'section',
        name: customName || materialList.name,
      },
      ...newMaterials,
    ])

    form.setFieldsValue({
      materials: [
        ...materials,
        ...[{ type: 'section', name: customName || materialList.name }],
        ...newMaterials,
      ],
    })

    setAddedMaterialList(newMaterials)
    setShowAddAssemblyModal(false)
    handleFormChange({}, form.getFieldsValue())
  }

  const handleOnTourClose = () => {
    setShowOnboarding(false)
    onOnboardingComplete()
  }

  return (
    <EstimatesFormStyled>
      {contextHolder}

      <Form
        form={form}
        // https://github.com/ant-design/ant-design/issues/22372 - required for form to update
        initialValues={{
          // estimate_date: dayjs(),
          // expiration_date: dayjs().add(7, 'days'),
          labor_label: 'Labor',
          labor_quantity: 1,
        }}
        name='dynamic_form_complex'
        onValuesChange={handleFormChange}
        autoComplete='off'
        layout='vertical'
        className='estimates-form'
        scrollToFirstError={true}
      >
        <div ref={estimateInfoRef}>
          <Row gutter={{ xs: 12, sm: 12, md: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={'number'}
                label={
                  <Space
                    direction='horizontal'
                    size={'small'}
                    split={<Divider type='vertical' />}
                  >
                    Estimate Number
                    {prevEstimateNumber && (
                      <a>
                        <Typography.Text
                          type='secondary'
                          italic
                          ellipsis
                          onClick={handleSetPreviousEstimateNumber}
                          style={{ maxWidth: '275px' }}
                        >
                          Last: {prevEstimateNumber}
                        </Typography.Text>
                      </a>
                    )}
                  </Space>
                }
                rules={[
                  {
                    required: true,
                    message: 'Missing estimate number',
                  },
                ]}
              >
                <Input
                  placeholder={'estimate123'}
                  data-testid='estimate-number-input'
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={'name'}
                label='Estimate Name'
                rules={[
                  {
                    required: false,
                    message: 'Missing estimate name',
                  },
                ]}
              >
                <Input
                  placeholder={'Estimate name'}
                  data-testid='estimate-name-input'
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 12, sm: 12, md: 16 }}>
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                name={'customer'}
                label='Customer'
                rules={[
                  {
                    required: true,
                    message: 'Missing customer',
                  },
                ]}
              >
                <CustomerSelect
                  createButtonVisible={true}
                  createButtonText={'Customer'}
                  createdCustomerObj={createdCustomer}
                  initialCustomerObj={selectedCustomer}
                  onAddClick={() => setCreateCustomer(true)}
                  onCustomerChange={(id, customerObj) => {
                    handleSetSelectedCustomer(customerObj)
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row gutter={{ xs: 12, sm: 12, md: 16 }}>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={'contact_name'}
                    label='Contact Name'
                    rules={[
                      {
                        required: false,
                        message: 'Missing contact name',
                      },
                    ]}
                  >
                    <Input
                      placeholder={'John Doe'}
                      data-testid='contact-name-input'
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Form.Item
                    name={'contact_email'}
                    label='Contact Email'
                    rules={[
                      {
                        required: false,
                        message: 'Missing contact email',
                      },
                    ]}
                  >
                    <Input
                      placeholder={'johndoe@company.com'}
                      data-testid='contact-email-input'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={{ xs: 12, sm: 12, md: 16 }}>
            {selectedCustomer && !mobile && (
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  label='Billing Address'
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <CustomerDetailCard
                    customer={selectedCustomer}
                    // extra={
                    //   <a>
                    //     Custom Address <EditFilled />
                    //   </a>
                    // }
                  />
                </Form.Item>
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              md={{ span: 12, offset: selectedCustomer ? 0 : 12 }}
            >
              <Row gutter={{ xs: 12, sm: 12, md: 16 }}>
                <Col xs={12} sm={12} md={12}>
                  <Form.Item
                    name={'estimate_date'}
                    label='Estimate Date'
                    rules={[
                      {
                        required: false,
                        message: 'Missing estimate date',
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      data-testid='estimate-date-input'
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={12}>
                  <Form.Item
                    name={'expiration_date'}
                    label='Expiry Date'
                    rules={[
                      {
                        required: false,
                        message: 'Missing estimate date',
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      data-testid='estimate-expiry-input'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Divider />
        </div>

        <div className={mobile ? '' : 'form-section'} ref={estimateScopeRef}>
          <Row gutter={16}>
            <Col span={24}>
              <Typography.Title level={4}>Scope of work</Typography.Title>
            </Col>
            <Col span={24}>
              <Form.Item name='work_scope'>
                <RichTextEditor initialContent={initialValues?.work_scope} />
              </Form.Item>
            </Col>
          </Row>

          <Divider />
        </div>

        <div className={mobile ? '' : 'form-section'} ref={estimateItemsRef}>
          <Row gutter={16}>
            <Col span={24}>
              <Typography.Title level={4}>Items</Typography.Title>
            </Col>
            <Col span={24}>
              <EstimatesMaterialsForm
                form={form}
                mobile={mobile}
                initialMaterials={initialMaterials}
                disableMarkup={hasCustomSubtotal}
                appliedAssembly={appliedAssembly}
                inlineCreatedMaterials={inlineCreatedMaterials}
                onMaterialsChange={() => {
                  calculateTotals(form.getFieldsValue(), appliedTaxes)
                }}
                onCreateNewMaterialClick={(record: any) => {
                  setQuickCreateMaterial({
                    ...record,
                    key: record.key,
                    invoicedAt: new Date(),
                  })
                }}
                onAddAssemblyClick={() => setShowAddAssemblyModal(true)}
              />
            </Col>
          </Row>
        </div>

        {/* Legacy - TODO remove */}
        {initialValues?.labor_breakdown &&
          initialValues?.labor_breakdown.length > 0 && (
            <div className={mobile ? '' : 'form-section'}>
              <Divider />
              <Row gutter={16}>
                <Col span={24}>
                  <Typography.Title level={4}>Labor</Typography.Title>
                </Col>
                <Col span={24}>
                  <EstimatesFormLabor
                    initialValues={initialValues?.labor_breakdown}
                    form={form}
                    onChange={() => {
                      const allValues = form.getFieldsValue()
                      handleFormChange({}, allValues)
                    }}
                  />
                </Col>
              </Row>
            </div>
          )}

        <div className={mobile ? '' : 'form-section'} ref={estimateTotalsRef}>
          <Row gutter={16}>
            <Col span={24}>
              <Divider orientation='left' />

              <Row gutter={16}>
                {/* Totals container */}
                <Col xs={24} sm={24} md={{ span: 12, offset: 12 }}>
                  <Row gutter={16}>
                    <Col span={8} offset={16}>
                      <Form.Item name='currency'>
                        <CurrencySelect
                          onChange={setSelectedCurrency}
                          defaultCurrency={
                            initialValues?.currency || defaultCurrency
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='selected_tax_id'>
                        <TaxInputManager
                          // @ts-ignore
                          taxOptions={taxes || []}
                          appliedTaxes={appliedTaxes}
                          createButtonVisible={true}
                          onAddClick={handleCreateTax}
                          onApplyTax={(tax: any) => {
                            handleSetAppliedTaxes([...appliedTaxes, tax])
                            calculateTotals(form.getFieldsValue(), [
                              ...appliedTaxes,
                              tax,
                            ])

                            // Reset input
                            form.setFieldValue('selected_tax_id', null)
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item name='discount_percentage'>
                        <DiscountInputManager
                          createButtonVisible={true}
                          onAddClick={handleCreateTax}
                          onApplyDiscount={(discount: any) => {
                            form.setFieldValue('discount_percentage', discount)

                            calculateTotals(form.getFieldsValue(), appliedTaxes)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item name='_totals'>
                    <EstimatesFormTaxTotalSummary
                      currency={selectedCurrency}
                      taxes={appliedTaxes}
                      form={form}
                      allowTaxRemove={true}
                      allowDiscountRemove={true}
                      allowClearSubtotal={hasCustomSubtotal}
                      onEditSubtotal={() => {
                        setShowCustomSubtotalModal(true)
                      }}
                      onClearSubtotal={() => {
                        setHasCustomSubtotal(false)
                        form.setFieldValue('custom_subtotal', null)
                        calculateTotals(form.getFieldsValue(), appliedTaxes)
                      }}
                      onTaxRemove={(tax: any) => {
                        const remainingTaxes = appliedTaxes.filter(
                          (taxObj) => taxObj?.id !== tax?.id
                        )
                        handleSetAppliedTaxes([...remainingTaxes])
                        calculateTotals(form.getFieldsValue(), remainingTaxes)
                      }}
                      onDiscountRemove={() => {
                        form.setFieldValue('discount_percentage', null)
                        calculateTotals(form.getFieldsValue(), appliedTaxes)
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Divider />

        <div className={mobile ? '' : 'form-section'}>
          <Row
            gutter={{ xs: 12, sm: 12, md: 16 }}
            style={{ marginBottom: '8em' }}
          >
            <Col span={24}>
              <Collapse
                style={{ padding: '1em 0' }}
                defaultActiveKey={
                  initialValues?.additional_content ? ['1'] : []
                }
                expandIconPosition='start'
                collapsible='header'
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
                ghost
                items={[
                  {
                    key: '1',
                    label: (
                      <>
                        <Typography.Title
                          level={4}
                          style={{ marginTop: '-3px' }}
                        >
                          Additional content
                        </Typography.Title>
                        <Typography.Text type='secondary'>
                          Add additional information to the estimate PDF such as
                          payment terms or exceptions.{' '}
                          {!initialValues?.id && (
                            <>
                              Set default values in{' '}
                              <a onClick={handleNavigateToSettings}>settings</a>
                              .
                            </>
                          )}
                        </Typography.Text>
                      </>
                    ),
                    children: (
                      <>
                        <Form.Item name='additional_content'>
                          <RichTextEditor
                            initialContent={initialValues?.additional_content}
                          />
                        </Form.Item>
                      </>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </div>

        {/* Hidden fields for default labor */}
        <Form.Item hidden name={'labor_rate'}>
          <InputNumber data-testid='estimate-labor-rate-hidden-input' />
        </Form.Item>
        <Form.Item hidden name={'labor_label'}>
          <Input data-testid='estimate-labor-label-hidden-input' />
        </Form.Item>
        <Form.Item hidden name={'custom_subtotal'}>
          <Input data-testid='estimate-custom-subtotal-hidden-input' />
        </Form.Item>

        <FooterToolbar
          style={footerToolbarStyle}
          extra={
            !mobile && (
              <AutoSavingLabel
                errorString={draftEstimateError.current}
                isSaving={
                  isSavingDraftEstimate ||
                  isUpdatingEstimate ||
                  isCreatingEstimate
                }
              />
            )
          }
        >
          <Form.Item>
            <Space ref={estimateSaveRef}>
              <EstimatesFormTotalDescription
                hideLabel={mobile}
                currency={selectedCurrency}
              />
              <Button
                onClick={handleCancel}
                data-testid='cancel-estimate-button'
              >
                Cancel
              </Button>
              {/* {renderSubmitButton( */}
              <Button
                type='primary'
                // disabled={isSubmitButtonDisabled()}
                onClick={handleSubmit}
                loading={isCreatingEstimate || isUpdatingEstimate}
                data-testid='save-estimate-button'
              >
                {edit ? 'Update' : 'Save'}
              </Button>
              {/* )} */}
            </Space>
          </Form.Item>
        </FooterToolbar>
      </Form>

      <TaxFormDrawer
        open={createTax}
        onClose={() => setCreateTax(false)}
        onTaxCreated={handleTaxCreated}
      />
      <CustomerFormDrawer
        open={createCustomer}
        onClose={() => setCreateCustomer(false)}
        onCustomerSave={({ data }: any) => {
          handleSetSelectedCustomer(data)
          setCreatedCustomer(data)
          form.setFieldValue('customer', data?.id)
          setCreateCustomer(false)
        }}
      />

      <MaterialFormDrawer
        allowServiceCreate={false}
        open={quickCreateMaterial}
        material={quickCreateMaterial}
        newCategory={createdCategory}
        newDistributor={createdDistributor}
        onClose={() => setQuickCreateMaterial(null)}
        onSave={(data) => {
          if (data?.id) {
            setInlineCreatedMaterials({
              ...inlineCreatedMaterials,
              [quickCreateMaterial.key]: data,
            })
          }
          setQuickCreateMaterial(null)
        }}
        onCreateNewCategory={() => {
          setCreateCategory(true)
        }}
        onCreateNewDistributor={() => {
          setCreateDistributor(true)
        }}
      />

      {/* Order matters, this needs to stack on top of the Material create form */}
      <DistributorCreateFormDrawer
        open={createDistributor}
        mode='create'
        width={425}
        onSave={onDistributorSave}
        onClose={handleDistributorFormClose}
      />

      <CategoryCreateFormDrawer
        open={createCategory}
        mode='create'
        width={425}
        onSave={onCategorySave}
        onClose={handleCategoryFormClose}
      />
      <MaterialListSelectorModal
        open={showAddAssemblyModal}
        onCancel={() => setShowAddAssemblyModal(false)}
        onListSelect={handleAddAssemblyItems}
      />
      <CustomSubtotalModal
        open={showCustomSubtotalModal}
        currentSubtotal={form.getFieldValue('subtotal')}
        onSubtotalSave={(subtotal) => {
          setHasCustomSubtotal(true)
          form.setFieldValue('custom_subtotal', subtotal)
          calculateTotals(form.getFieldsValue(), appliedTaxes)
          setShowCustomSubtotalModal(false)
        }}
        onClose={() => setShowCustomSubtotalModal(false)}
      />

      <Tour
        open={showOnboarding}
        onClose={handleOnTourClose}
        mask={true}
        disabledInteraction={true}
        steps={onboardingSteps}
        type='primary'
        zIndex={999}
        gap={{ offset: 16 }}
      />
    </EstimatesFormStyled>
  )
}

EstimatesForm.displayName = 'EstimatesForm'
