import { Settings } from 'react-feather'
import React from 'react'
import type { MenuProps } from 'antd'
import { Layout, Menu, Flex, message } from 'antd'
import {
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { H } from 'highlight.run'
import { SignIn, SignUp, SignedIn, SignedOut } from '@clerk/clerk-react'
import { AppNavigationStyled } from './styles'

import { useGetMeQuery } from '../../graphql/_generated-hooks'
import { getPathnameByLevel } from '../../utils'
import { useAppStore } from '../../stores/appStore'
import { Logo } from '../../components/Logo'
import { DemoModeBanner } from '../../components/DemoModeBanner'
import { FullpageAppLoader } from '../../components/FullpageAppLoader'
import { AnimatedComponent } from '../../components/AnimatedComponent'
import { MaterialsView } from '../MaterialsView'
import { MaterialsDetailView } from '../MaterialsDetailView'
import { CustomerListView } from '../CustomerListView'
import { CustomerDetailView } from '../CustomerDetailView'
import { EstimateListView } from '../EstimateListView'
import { EstimatesFormView } from '../EstimatesFormView'
import { EstimatesDetailView } from '../EstimatesDetailView'
import { PreferenceViewMobile } from '../PreferenceViewMobile'
import { OnboardingFormView } from '../OnboardingFormView'

const { Content, Footer } = Layout

export const AppViewMobile: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const [current, setCurrent] = React.useState<string[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const searchObj = Object.fromEntries(searchParams.entries())
  const materialUsage = useAppStore((state: any) => state.materials)
  const estimateUsage = useAppStore((state: any) => state.estimates)
  const activePlan = useAppStore((state: any) => state.plan)
  const isExperimentalOrg = useAppStore((state: any) => state.isExperimental)
  const setMaterialsEstimatesPlan = useAppStore(
    (state: any) => state.setMaterialsEstimatesPlan
  )
  const setQuickbooksIntegrationConfig = useAppStore(
    (state: any) => state.setQuickbooksConfig
  )
  const setFeatureFlag = useAppStore((state: any) => state.setIsExperimental)

  React.useEffect(() => {
    if (location.pathname) {
      const snippets = location.pathname.split('/').filter((i) => i)
      if (snippets.length > 0) {
        setCurrent(snippets)
      }
    }
  }, [location])

  const {
    data: { me } = {},
    refetch,
    loading: loadingMe,
    updateQuery: updateMe,
  } = useGetMeQuery({
    onCompleted({ me }) {
      if (!me || !me.id) {
        return navigate('/sign-in')
      }

      if (me?.id && me?.email && process.env.NODE_ENV === 'production') {
        H.identify(me.email, {
          id: me.id,
          name: `${me.given_name} ${me.family_name}`,
          // @ts-ignore
          avatar: me.picture,
          // @ts-ignore
          organization: me?.organization?.id,
        })
      }

      if (me?.onboarding?.length) {
        const welcomeStep = me.onboarding.find(
          (item: any) => item.step === 'welcome'
        )
        const onboardingStep = me.onboarding.find(
          (item: any) => item.step === 'onboarding'
        )
        if (!onboardingStep?.completed) {
          navigate('/onboarding')
        } else if (!welcomeStep?.completed) {
          navigate('/estimates/create?onboarding=true')
        }
      }

      if (me?.organization?.id) {
        setMaterialsEstimatesPlan(
          me?.organization?.materialCount || 0,
          me?.organization?.estimateCount || 0,
          me?.organization?.plan || null
        )

        setFeatureFlag(me?.organization?.experimental || false)
      }
    },
    onError(error: any) {
      // TODO handle unauthenticated error
      console.error(error)
    },
  })

  const menuItems: MenuProps['items'] = [
    {
      label: 'Estimates',
      key: 'estimates',
    },
    {
      label: 'Materials',
      key: 'materials',
    },
    {
      label: 'Customers',
      key: 'customers',
    },
    {
      label: <Settings size={16} style={{ marginBottom: '-3px' }} />,
      key: 'settings/organization',
    },
  ]

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent([e.key])
    navigate(`/${e.key}`, { replace: true })
  }

  const handleNavigateAndRefresh = () => {
    // Navigate to a specific route
    navigate('/materials')

    // Perform a hard refresh
    window.location.reload()
  }

  const shouldHideDemoBanner = (pathname: string) => {
    const patterns = ['^/estimates/create$', '^/estimates/edit$']
    return patterns.some((pattern) => new RegExp(pattern).test(pathname))
  }

  return (
    <Layout className='mobile-layout'>
      {contextHolder}
      {loadingMe && <FullpageAppLoader />}
      {!loadingMe && (
        <Content style={{ marginBottom: '80px' }}>
          <Routes key={getPathnameByLevel(location.pathname, 1)}>
            <Route
              path='/sign-in/*'
              element={
                <>
                  <div className='sticky-search'>
                    <Flex justify='space-around'>
                      <Logo mode='mobile' />
                    </Flex>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '40px',
                    }}
                  >
                    <SignIn routing='path' path='/sign-in' />
                  </div>
                </>
              }
            />
            <Route
              path='/sign-up/*'
              element={<SignUp routing='path' path='/sign-up' />}
            />

            <Route
              path='/onboarding'
              element={
                <SignedIn>
                  <AnimatedComponent>
                    <OnboardingFormView />
                  </AnimatedComponent>
                </SignedIn>
              }
            />

            <Route
              element={
                <>
                  <SignedIn>
                    <Layout>
                      <DemoModeBanner
                        mobile={true}
                        showBanner={
                          !me?.organization?.livemode &&
                          !shouldHideDemoBanner(location.pathname)
                        }
                        onLivemodeChange={() => {
                          message.success(
                            'Success! Your organization is now live.'
                          )
                          handleNavigateAndRefresh()
                        }}
                      />
                      <Outlet />
                      <Footer
                        style={{
                          position: 'fixed',
                          bottom: 0,
                          zIndex: 1,
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          padding: 0,
                        }}
                      >
                        <AppNavigationStyled>
                          <Menu
                            mode='horizontal'
                            selectedKeys={current}
                            items={menuItems}
                            onClick={onClick}
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-around', // or 'space-between'
                            }}
                          />
                        </AppNavigationStyled>
                      </Footer>
                    </Layout>
                  </SignedIn>
                </>
              }
            >
              {/* Same routes as desktop but with mobile-optimized components */}
              <Route
                path='/materials'
                element={
                  <AnimatedComponent>
                    <MaterialsView me={me} mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/materials/:id'
                element={
                  <AnimatedComponent>
                    <MaterialsDetailView mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/estimates'
                element={
                  <AnimatedComponent>
                    <EstimateListView me={me} mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/estimates/:id'
                element={
                  <AnimatedComponent>
                    <EstimatesDetailView me={me} mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/estimates/create'
                element={
                  <AnimatedComponent>
                    <EstimatesFormView me={me} mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/estimates/:id/edit'
                element={
                  <AnimatedComponent>
                    <EstimatesFormView me={me} mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/customers'
                element={
                  <AnimatedComponent>
                    <CustomerListView mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/customers/:id'
                element={
                  <AnimatedComponent>
                    <CustomerDetailView mobile={true} />
                  </AnimatedComponent>
                }
              />
              <Route
                path='/settings/organization'
                element={
                  <AnimatedComponent>
                    <PreferenceViewMobile
                      me={me}
                      redirectObj={searchObj}
                      activePlan={activePlan}
                      materialUsage={materialUsage}
                      estimateUsage={estimateUsage}
                    />
                  </AnimatedComponent>
                }
              />
              {/* <Route path="/settings/*" element={<PreferenceView mobile={true} />} /> */}
              <Route path='*' element={<Navigate to='/materials' />} />
            </Route>
          </Routes>
          <SignedOut>
            <Navigate to='/sign-in' />
          </SignedOut>
        </Content>
      )}
    </Layout>
  )
}

AppViewMobile.displayName = 'AppViewMobile'
