// views/GlobalCatalogView.tsx
import {
  Button,
  Card,
  Upload,
  message,
  Typography,
  Tabs,
  Space,
  Table,
  Divider,
  Tag,
} from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { UploadOutlined } from '@ant-design/icons'
import * as React from 'react'
import { useAuth } from '@clerk/clerk-react'

import { useGetProductsQuery } from '../../graphql/_generated-hooks'
import { PageTitle } from '../../components/PageTitle'
import { SimpleSearchSelect } from '../../components/SimpleSearchSelect'

const { REACT_APP_API_URL, REACT_APP_ADMIN_ORG_ID } = process.env

export interface PreferenceBillingViewProps {
  me?: any
}
export const AdminView = ({ me }: PreferenceBillingViewProps) => {
  const { getToken } = useAuth()
  const [uploadHeaders, setUploadHeaders] = React.useState<any>({})
  const [uploadedPrices, setUploadedPrices] = React.useState<any[]>([])
  const [uploadedProducts, setUploadedProducts] = React.useState<
    Array<{
      manufacturer_code: string
      id: string
      name: string
    }>
  >([])
  const [selectedProduct, setSelectedProduct] = React.useState<any>({})

  const {
    data,
    loading,
    refetch: refetchProducts,
  } = useGetProductsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
  })

  // Set up auth headers
  React.useEffect(() => {
    const setupAuth = async () => {
      const token = await getToken({ template: 'volt-jwt-template' })
      setUploadHeaders({
        authorization: `Bearer ${token}`,
      })
    }
    setupAuth()
  }, [getToken])

  const handleFetchProducts = async (value: string): Promise<any> => {
    return refetchProducts({
      input: { search: value, limit: 100, includeGlobal: true },
    }).then(({ data }: any) => {
      return data?.products?.data?.map((item: any) => ({
        value: item.id,
        data: item,
        label: `${item.is_global ? '(GLOBAL) ' : ''} ${item.name} - ${item.id}`,
      }))
    })
  }

  const handleProductUpload = async (info: any) => {
    const { status, response } = info.file

    if (status === 'done') {
      message.success(`${info.file.name} uploaded successfully`)

      // Show results
      if (response?.products?.length) {
        message.info(
          `Created: ${response.created}, Updated: ${response.updated}, Products: ${response.products.length}`
        )
        setUploadedProducts(response.products)
      }
    } else if (status === 'error') {
      message.error(
        `${info.file.name} upload failed: ${response?.error || 'Unknown error'}`
      )
    }
  }

  const handlePriceUpload = async (info: any) => {
    const { status, response } = info.file

    if (status === 'done') {
      message.success(`${info.file.name} uploaded successfully`)

      // Show detailed results
      message.info(
        `Created: ${response.created}, Updated: ${response.updated}, ${
          response.errors.length ? `Errors: ${response.errors.length}` : ''
        }`
      )

      setUploadedPrices(response.prices)

      // If there were any errors, show them in a warning message
      if (response.errors.length) {
        message.warning('Some rows had errors. Check the error log below.')
      }
    } else if (status === 'error') {
      message.error(
        `${info.file.name} upload failed: ${response?.error || 'Unknown error'}`
      )
    }
  }

  return (
    <Content style={{ padding: '2em' }}>
      <PageTitle
        title='Admin Panel'
        subtitle='Upload and manage global products and prices. Admin access only.'
      />

      <Card style={{ marginTop: '2em' }}>
        <Tabs
          items={[
            {
              key: 'products',
              label: 'Products',
              children: (
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Typography.Title level={4}>Upload Products</Typography.Title>
                  <Typography.Text type='secondary'>
                    Upload a CSV file containing product information. Required
                    fields: name, manufacturer_code, audience
                  </Typography.Text>

                  <Divider />

                  <SimpleSearchSelect
                    labelInValue={true}
                    onSelect={(value: any, option: any) => {
                      setSelectedProduct(option.data)
                    }}
                    placeholder='Search by item name'
                    fetchOptions={handleFetchProducts}
                    optionList={data?.products?.data?.map((item: any) => ({
                      value: item.id,
                      data: item,
                      label: `${item.is_global ? '(GLOBAL) ' : ''} ${
                        item.name
                      } - ${item.id}`,
                    }))}
                    style={{ margin: 0, width: '100%' }}
                  />

                  {selectedProduct?.id && (
                    <Space direction='vertical'>
                      <Typography.Text copyable>
                        ID: {selectedProduct.id}
                      </Typography.Text>
                      <Typography.Text>
                        Name: {selectedProduct.name}
                      </Typography.Text>
                    </Space>
                  )}

                  <Divider />

                  <Upload
                    name='products_csv'
                    action={`${REACT_APP_API_URL}/global-catalog/products/upload`}
                    headers={uploadHeaders}
                    onChange={handleProductUpload}
                    accept='.csv'
                    onDrop={handleProductUpload}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload Products CSV
                    </Button>
                  </Upload>

                  {uploadedProducts.length > 0 && (
                    <Table
                      rowKey='id'
                      dataSource={uploadedProducts}
                      columns={[
                        { title: 'Name', dataIndex: 'name', ellipsis: true },
                        {
                          title: 'Manufacturer Code',
                          dataIndex: 'manufacturer_code',
                        },
                        {
                          title: 'ID',
                          dataIndex: 'id',
                          render(value, record, index) {
                            return (
                              <Typography.Text copyable>
                                {value}
                              </Typography.Text>
                            )
                          },
                        },
                      ]}
                    />
                  )}
                </Space>
              ),
            },
            {
              key: 'prices',
              label: 'Prices',
              children: (
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Typography.Title level={4}>Upload Prices</Typography.Title>
                  <Typography.Text type='secondary'>
                    Upload a CSV file containing price information. Required
                    fields: manufacturer_code, vendor_name, currency,
                    unit_amount
                  </Typography.Text>

                  <Divider />

                  <Upload
                    name='prices_csv'
                    action={`${REACT_APP_API_URL}/global-catalog/prices/upload`}
                    headers={uploadHeaders}
                    onChange={handlePriceUpload}
                    onDrop={handlePriceUpload}
                    accept='.csv'
                  >
                    <Button icon={<UploadOutlined />}>Upload Prices CSV</Button>
                  </Upload>

                  {uploadedPrices.length > 0 && (
                    <>
                      <Typography.Title level={5}>
                        Uploaded Prices
                      </Typography.Title>
                      <Table
                        rowKey='id'
                        dataSource={uploadedPrices}
                        columns={[
                          {
                            title: 'Product',
                            dataIndex: 'name',
                            sorter: (a, b) =>
                              a.product_name.localeCompare(b.product_name),
                          },
                          {
                            title: 'Vendor',
                            dataIndex: 'vendor_name',
                            sorter: (a, b) =>
                              a.vendor_name.localeCompare(b.vendor_name),
                          },
                          {
                            title: 'Currency',
                            dataIndex: 'currency',
                            filters: [
                              { text: 'USD', value: 'USD' },
                              { text: 'CAD', value: 'CAD' },
                            ],
                            onFilter: (value, record) =>
                              record.currency === value,
                          },
                          {
                            title: 'Amount',
                            dataIndex: 'unit_amount',
                            render: (amount) =>
                              new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(amount / 100),
                          },

                          {
                            title: 'ID',
                            dataIndex: 'id',
                            render(value, record, index) {
                              return (
                                <Typography.Text copyable>
                                  {value}
                                </Typography.Text>
                              )
                            },
                          },
                        ]}
                        size='small'
                        pagination={{ pageSize: 10 }}
                      />
                    </>
                  )}
                </Space>
              ),
            },
          ]}
        />
      </Card>
    </Content>
  )
}

AdminView.displayName = 'AdminView'
