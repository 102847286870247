import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const OnboardingFormViewStyled = styled.div`
  display: flex;
  min-height: 100vh;

  .form-container {
    flex: 1;
    padding: 20px 40px 40px 40px;
    max-width: 100%;
    background-color: ${(props) => props.theme.colors.white};

    @media (min-width: 992px) {
      max-width: 50%;
      padding: 64px;
    }
  }

  .image-container {
    display: none;

    @media (min-width: 992px) {
      display: block;
      flex: 1;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url('https://voltappstorage01.blob.core.windows.net/public-assets/app/onboarding_1.jpg');
      background-size: cover;
      background-position: center;
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      width: 50%;

      // Angled white bar
      &::after {
        content: '';
        position: absolute;
        background: ${(props) => props.theme.colors.white};

        width: 200%; // Extra wide to account for rotation
        height: 50%;
        bottom: -40%;
        left: -50%; // Pull back to center
        transform: rotate(-10deg); // Create the angle
        z-index: 1;
      }
    }

    .welcome-content-container {
      width: 400px;
      margin: 100px auto;

      // position: absolute;
      // top: 40%;
      // left: 50%;
      // transform: translate(-50%, -50%);

      .welcome-content-bg {
        // background-color: rgba(0, 0, 0, 0.25);
        border-radius: 8px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span,
      .ant-steps-item-title,
      .ant-steps-item-description {
        color: ${(props) => props.theme.colors.white} !important;
      }
      .ant-steps-item-description {
        opacity: 0.7;
      }
      .ant-steps-item-icon {
        background: none !important;
      }
    }

    .logo-container {
      position: absolute;
      right: 25px;
      bottom: 25px;
      z-index: 2; // Above the white bar
      text-align: center;
      width: 150px; // Adjust based on your logo size

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 32px;
  }

  .step-indicator {
    margin-bottom: 1em;
  }
  .page-title {
    margin-bottom: 2em;
  }
`
