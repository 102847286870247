import * as React from 'react'
import {
  message,
  Flex,
  Form,
  Input,
  Select,
  Button,
  Space,
  Steps,
  Typography,
  Row,
  Col,
} from 'antd'
import { TeamOutlined, DollarOutlined, ToolOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useAppStore } from '../../stores/appStore'
import { MAIN_CURRENCIES, OTHER_CURRENCIES } from '../../config/default'

import {
  useUpdateOrganizationOnboardingStatusMutation,
  useUpdateMeMutation,
} from '../../graphql/_generated-hooks'
import { PageTitle } from '../../components/PageTitle/PageTitle'
import { OnboardingFormViewStyled } from './styles'

const { Title, Text } = Typography
const { Option } = Select

const COMPANY_SIZES = ['1-10 employees', '11-25 employees', '25+ employees']

const CURRENCIES = [...MAIN_CURRENCIES, ...OTHER_CURRENCIES]

export interface OnboardingFormViewProps {
  mobile?: boolean
}

export const OnboardingFormView = ({
  mobile,
  ...props
}: OnboardingFormViewProps) => {
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const [updateOrganizationOnboardingStatusMutation, { data, loading, error }] =
    useUpdateOrganizationOnboardingStatusMutation()
  const [updateMeMutation] = useUpdateMeMutation()

  const handleSubmit = async (values: any) => {
    updateOrganizationOnboardingStatusMutation({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted: () => {
        updateMeMutation({
          variables: {
            input: {
              onboarding: [
                {
                  step: 'onboarding',
                  completed: true,
                },
              ],
            },
          },
        })

        navigate('/estimates/create?onboarding=true')
      },
      onError: (error: any) => {
        messageApi.error(error.message)
      },
    })
  }

  const renderForm = () => (
    <OnboardingFormViewStyled>
      <div className='form-container'>
        {contextHolder}
        <div className='step-indicator'>
          <Text type='secondary'>Step 1 of 1</Text>
        </div>
        <div className='page-title'>
          <PageTitle
            title={'Lets set up your organization'}
            subtitle={
              'Tell us about your business so we can customize your experience.'
            }
          />
        </div>

        <Form
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
          initialValues={{
            currency: 'USD',
            profession: 'electrical',
            company_size: COMPANY_SIZES[0],
          }}
        >
          <Form.Item
            data-1p-ignore=''
            name='name'
            label='Company Name'
            rules={[
              { required: true, message: 'Please enter your company name' },
            ]}
          >
            <Input size='large' placeholder='Enter your company name' />
          </Form.Item>

          <Row gutter={24}>
            <Col xs={24} md={24}>
              <Form.Item
                name='profession'
                label="What's your industry?"
                rules={[
                  { required: true, message: 'Please select your industry' },
                ]}
              >
                <Select
                  size='large'
                  placeholder='Select your industry'
                  suffixIcon={<ToolOutlined />}
                >
                  <Option value='electrical'>Electrical</Option>
                  <Option value='plumbing'>Plumbing</Option>
                  <Option value='hvac'>HVAC</Option>
                  <Option value='roofing'>Roofing</Option>
                  <Option value='painting'>Painting</Option>
                  <Option value='concrete'>Concrete</Option>
                  <Option value='general'>General Contracting</Option>
                  <Option value='other'>Other</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={24}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.profession !== currentValues.profession
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('profession') === 'other' ? (
                    <Form.Item
                      name='custom_profession'
                      label='Specify your industry'
                      rules={[
                        {
                          required: true,
                          message: 'Please specify your industry',
                        },
                      ]}
                    >
                      <Input size='large' placeholder='Enter your industry' />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                name='currency'
                label='Preferred Currency'
                rules={[
                  { required: true, message: 'Please select your currency' },
                ]}
              >
                <Select
                  size='large'
                  placeholder='Select currency'
                  suffixIcon={<DollarOutlined />}
                >
                  {CURRENCIES.map((currencyObject) => (
                    <Option
                      key={currencyObject.currency}
                      value={currencyObject.currency}
                    >
                      {currencyObject.currency}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name='company_size'
                label='Company Size'
                rules={[
                  {
                    required: true,
                    message: 'Please select your company size',
                  },
                ]}
              >
                <Select
                  size='large'
                  placeholder='Select company size'
                  suffixIcon={<TeamOutlined />}
                >
                  {COMPANY_SIZES.map((size) => (
                    <Option key={size} value={size}>
                      {size}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button
              loading={loading}
              type='primary'
              size='large'
              htmlType='submit'
              block
            >
              Complete Setup
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className='image-container'>
        <div className='welcome-content-container'>
          <div className='welcome-content-bg'>
            <Title level={1}>Welcome to VoltPro!</Title>

            <Text>
              You are a few clicks away from creating accurate, professional
              estimates that help you secure more jobs, boost profits.
            </Text>

            <div style={{ marginTop: '2em' }}>
              <Steps
                size='default'
                direction='vertical'
                current={3}
                items={[
                  {
                    title: 'Build estimates',
                    description: 'Create detailed estimates in minutes.',
                  },
                  {
                    title: 'Manage material costs',
                    description:
                      'Use built-in prices or create custom items to accurately track material costs.',
                  },
                  {
                    title: 'Create assemblies',
                    description:
                      'Build reusable assemblies to save time and improve the consistency of your estimates.',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className='logo-container'>
          <img src='/logo_text_medium.png' alt='logo' />
        </div>
      </div>
    </OnboardingFormViewStyled>
  )

  return renderForm()
}

OnboardingFormView.displayName = 'OnboardingFormView'
