import { List, Flex, Typography, Space, Row, Col } from 'antd'
import * as React from 'react'

import { MaterialsListItemMobileStyled } from './styles'

export interface MaterialsListItemMobileProps {
  title?: React.ReactNode
  total?: React.ReactNode
  metaInfo?: { label: string; value: any }[]
  actions?: React.ReactNode[]
}

export const MaterialsListItemMobile = ({
  title,
  total,
  metaInfo = [],
  actions = [],
}: MaterialsListItemMobileProps) => {
  return (
    <List.Item>
      <MaterialsListItemMobileStyled>
        <Flex vertical gap='middle' style={{ width: '100%' }}>
          {/* Title Row with Actions */}
          <Flex justify='space-between' align='flex-start'>
            <div className='title-content'>{title}</div>
            {actions.length > 0 && (
              <div className='actions'>
                <Space>
                  {actions.map((action, index) => (
                    <React.Fragment key={index}>{action}</React.Fragment>
                  ))}
                </Space>
              </div>
            )}
          </Flex>

          {/* Meta Information */}
          {metaInfo.length > 0 && (
            <Flex vertical style={{ width: '100%' }}>
              {metaInfo.map(({ label, value }, index) => (
                <Flex key={index} justify='space-between' className='meta-item'>
                  <Typography.Text type='secondary'>{label}</Typography.Text>
                  <Typography.Text>{value}</Typography.Text>
                </Flex>
              ))}
            </Flex>
          )}

          {/* Total */}
          {total && (
            <Flex justify='flex-end' style={{ marginTop: '4px' }}>
              <Typography.Text strong>{total}</Typography.Text>
            </Flex>
          )}
        </Flex>
      </MaterialsListItemMobileStyled>
    </List.Item>
  )
}

MaterialsListItemMobile.displayName = 'MaterialsListItemMobile'
