import { Typography, Flex } from 'antd'
import * as React from 'react'

import { theme } from '../../styles/themes/default'
import { MaterialNameDescriptionStyled } from './styles'
import { capitalizeString } from '../../utils'

import { VoltProIcon } from '../VoltProIcon'

const { Title, Paragraph } = Typography

export interface MaterialNameDescriptionProps {
  name: string
  description?: string | null
  productLinkId?: string | null
  copyName?: boolean
  boldName?: boolean
  globalCatalog?: boolean
}

export const MaterialNameDescription = ({
  name,
  description,
  productLinkId,
  copyName = true,
  boldName = true,
  globalCatalog,
  ...props
}: MaterialNameDescriptionProps) => {
  const renderNameDescription = () => {
    return (
      <Typography>
        <Flex vertical gap={4}>
          <Flex gap='small' align='center'>
            {globalCatalog && (
              <span style={{ opacity: '0.35' }}>
                <VoltProIcon tooltipTitle='VoltPro global price catalog' />
              </span>
            )}
            <Paragraph
              strong={boldName}
              copyable={!!name && copyName}
              style={{ marginBottom: 0 }}
              ellipsis
              data-testid='material-name'
            >
              {name || '-'}
            </Paragraph>
          </Flex>
          {description && (
            <Paragraph
              type='secondary'
              ellipsis
              data-testid='material-trade-name'
            >
              {description}
            </Paragraph>
          )}
        </Flex>
      </Typography>
    )
  }

  return (
    <MaterialNameDescriptionStyled>
      {productLinkId ? (
        <a
          href={`/materials/${productLinkId}`}
          target='_blank'
          rel='noreferrer'
          style={{ color: theme.colors.primary }}
        >
          {renderNameDescription()}
        </a>
      ) : (
        renderNameDescription()
      )}
    </MaterialNameDescriptionStyled>
  )
}

MaterialNameDescription.displayName = 'MaterialNameDescription'
