import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components'

export const MaterialsListItemMobileStyled = styled.span`
  font-size: ${rem(16)};
  width: 100%;

  .title-content {
    font-weight: 600;
    min-width: 0; // Important for text truncation
    flex: 1;
  }

  .actions {
    flex: none; // Prevent shrinking
    margin-left: 8px;
  }

  .meta-item {
    padding: 8px 0;
    border-bottom: 1px solid #f5f5f5;
  }
`
